import type { NextPage } from 'next';
import { useRouter } from 'next/router';

import { AdobeAnalytics, MotionConfig, XumoToast, PageContent, PageMetadata } from './';
import { GeneralPageProps, ToastsProvider } from '../';
import { CookieConsentScripts } from 'ui';

import { useContext } from 'react';
import { PageContext } from 'ui/contexts';

export const GeneralPage: NextPage<GeneralPageProps> = ({ page, metadata, locale }) => {
  const router = useRouter();
  const slug = router.query.slug || [''];
  const path = '/' + (slug as string[]).join('/');

  const { buckets } = useContext(PageContext);

  //if the slug is home, append testing bucket to title
  const bucketSlug = buckets[0] === 0 ? 'original' : 'variation1'
  const titleABTest = slug == "" ? bucketSlug : ""

  return (
    <div key={'gen-page' + path} className="overflow-x-clip" suppressHydrationWarning>
      <CookieConsentScripts />
      <AdobeAnalytics path={metadata.host + path} locale={locale} title={metadata.title} abtest={titleABTest}/>
      <ToastsProvider>
        <PageMetadata {...metadata} path={path} />
        <MotionConfig>
          <main className="w-full" role="main">
            <PageContent page={page} />
          </main>
        </MotionConfig>
        <XumoToast />
      </ToastsProvider>
    </div>
  );
};
